(
    (fn) => {
        if (document.readyState !== 'loading') {
            fn()
        } else {
            document.addEventListener('DOMContentLoaded', fn)
        }
    }
)(
    () => {
        const debounce = (func) => {
            let timer

            return () => {
                if (!timer) {
                    timer = setTimeout(
                        () => {
                            func()
                            clearTimeout(timer)
                            timer = null
                        },
                        10
                    )

                    func()
                }
            }
        }

        document.querySelectorAll('.newsletter-slider').forEach(
            (slider) => {
                const makeStatic = () => {
                    const grid = slider.querySelector('.background-primary')
                    const closeBtn = slider.querySelector('[data-dismiss="newsletter-slider"]')

                    slider.classList.add('static')

                    if (grid) {
                        container.appendChild(grid)
                    }

                    if (closeBtn) {
                        closeBtn.parentNode.removeChild(closeBtn)
                    }

                    delete container.style.height
                }

                const container = slider.parentNode.querySelector('.newsletter-body-container')
                let followingScroll = false

                if (localStorage && localStorage.getItem('newsletter_signup') === 'complete') {
                    makeStatic()
                    return
                }

                slider.style.top = '100vh'
                slider.style.opacity = 1
                container.style.height = `${slider.scrollHeight - slider.clientHeight}px`
                followingScroll = true

                document.querySelectorAll('main article .markdown').forEach(
                    (article) => {
                        const fitInContainer = () => {
                            const containerRect = container.getBoundingClientRect()
                            const sliderRect = slider.querySelector('.container').getBoundingClientRect()
                            const triggerPoint = containerRect.y - sliderRect.y
 
                            if (triggerPoint <= 0) {
                                const grid = slider.querySelector('.background-primary')
                                const closeBtn = slider.querySelector('[data-dismiss="newsletter-slider"]')
                                window.removeEventListener('scroll', scroll)

                                slider.classList.add('static')

                                if (grid) {
                                    container.appendChild(grid)
                                }

                                if (closeBtn) {
                                    closeBtn.parentNode.removeChild(closeBtn)
                                }

                                delete container.style.height
                                followingScroll = false
                            }
                        }

                        const scrollBounced = () => {
                            const doc = document.documentElement
                            const body = document.body

                            if (!body) {
                                return
                            }

                            const percent = Math.max(0, Math.min(100, (doc.scrollTop || body.scrollTop) / ((doc.scrollHeight || body.scrollHeight) - doc.clientHeight) * 100))
                            const opacity = Math.min(.75, Math.max(0, (percent - 25) / 25))
                            const top = Math.min(100, Math.max(0, (percent - 5) * 2))

                            slider.style.top = `${100-top}vh`
                            fitInContainer()
                        }

                        const scroll = debounce(scrollBounced)

                        slider.addEventListener('click',
                            (e) => {
                                const close = e.target.getAttribute('data-dismiss') === 'newsletter-slider' ? e.target : e.target.closest('[data-dismiss="newsletter-slider"]')

                                if (!close) {
                                    return
                                }

                                const containerRect = container.getBoundingClientRect()
                                const sliderRect = slider.querySelector('.container').getBoundingClientRect()

                                e.preventDefault()
                                window.removeEventListener('scroll', scroll)

                                slider.classList.add('closing')
                                if (followingScroll) {
                                    followingScroll = false

                                    window.requestAnimationFrame(
                                        () => {
                                            slider.style.top = `${containerRect.y}px`
                                            setTimeout(makeStatic, 1000)
                                        }
                                    )
                                } else {
                                    setTimeout(
                                        () => {
                                            slider.style.top = '100vh'
                                        },
                                        100
                                    )
                                }
                            }
                        )

                        window.addEventListener('scroll', scroll)
                    }
                )
            }
        )
    }
)
